// src/firebase.js

// Import the functions you need from the SDKs
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCG0RN8R7Q0B5CCMnrsNYmrPY-6qX5ioXA",
  authDomain: "fraservalleyrealestatephoto.firebaseapp.com",
  projectId: "fraservalleyrealestatephoto",
  storageBucket: "fraservalleyrealestatephoto.appspot.com",
  messagingSenderId: "97110226662",
  appId: "1:97110226662:web:86f6f4b0574c760dcfed27",
  measurementId: "G-S1VCYNCG64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
